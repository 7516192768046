import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Icon, Menu } from 'semantic-ui-react';
import { About, Custom, Decision, Home, Language, NotFound, OpenAI, Other, Settings, Speech, Vision } from './pages';
import Footer from './components/Footer/Footer';
import styles from './App.module.scss';
import ImageAnalysis from './components/Vision/ImageAnalysis/ImageAnalysis';
import ReadText from './components/Vision/ImageAnalysis/v4/ReadText/ReadText';

const App: React.FC = () => {
  return (
    <div className={styles.App}>
    <Router>
      <Menu pointing inverted icon='labeled'>
        <Menu.Item as={Link} to="/"> <Icon name='home' /> Home</Menu.Item>
        <Menu.Item as={Link} to="/speech"><Icon name='comment outline' /> Speech</Menu.Item>
        <Menu.Item as={Link} to="/language"><Icon name='language' /> Language</Menu.Item>
        <Menu.Item as={Link} to="/vision"><Icon name='eye' /> Vision</Menu.Item>
        <Menu.Item as={Link} to="/decision"><Icon name='chart bar outline' /> Decision</Menu.Item>
        <Menu.Item as={Link} to="/openAI"><Icon name='code' /> OpenAI</Menu.Item>
        <Menu.Item as={Link} to="/custom"><Icon name='cogs' /> Custom</Menu.Item>
        <Menu.Item as={Link} to="/other"><Icon name='ellipsis horizontal' /> Other</Menu.Item>
      
      <Menu.Menu position='right'>
      <Menu.Item as={Link} to="/about"><Icon name='id card' />About</Menu.Item>
      <Menu.Item as={Link} to="/settings"><Icon name='settings' />Settings</Menu.Item>
      </Menu.Menu>
      </Menu>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/speech" element={<Speech />} />
        <Route path="/language" element={<Language />} />
        <Route path="/vision" element={<Vision />} />
        <Route path="/vision/imageAnalysis" element={<ImageAnalysis />} />
        <Route path='/vision/imageAnalysis/v4/readText' element={<ReadText />} />
        <Route path="/decision" element={<Decision />} />
        <Route path='/openAI' element={<OpenAI />} />
        <Route path='/custom' element={<Custom />} />
        <Route path="/other" element={<Other />} />
        <Route path="/about" element={<About />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </Router>
   
    </div>
  );
};

export default App;
