import React from "react";
import {  Card, Divider, Icon } from "semantic-ui-react";
import styles from "./styles.module.scss";
import CustomCard from "../components/CustomCard/CustomCard";

const Language: React.FC = () => {
    return (
        <div className={styles.Cognitive}>
        <h1>Language</h1>
        <Divider clearing />
        <div className={styles.center}>
        <h2>Content here will come soon...</h2>
        <h2>
          <Icon name='star' size='huge' loading color='purple' />
        </h2>
      </div>
        <Card.Group centered>
        <CustomCard 
            title='Summarize text-based content'
            infoHeader=''
            infoContent={[]}
            isInfoDisabled={true}
            tryIt=''
            isTryItDisabled={true}
            readMore=''
            isReadMoreDisabled={true}
            isVisible={false}
        />
         <CustomCard 
            title='Classify text'
            infoHeader=''
            infoContent={[]}
            isInfoDisabled={true}
            tryIt=''
            isTryItDisabled={true}
            readMore=''
            isReadMoreDisabled={true}
            isVisible={false}
        />
         <CustomCard 
            title='Answer Questions'
            infoHeader=''
            infoContent={[]}
            isInfoDisabled={true}
            tryIt=''
            isTryItDisabled={true}
            readMore=''
            isReadMoreDisabled={true}
            isVisible={false}
        />
         <CustomCard 
            title='Understand Conversation'
            infoHeader=''
            infoContent={[]}
            isInfoDisabled={true}
            tryIt=''
            isTryItDisabled={true}
            readMore=''
            isReadMoreDisabled={true}
            isVisible={false}
        />
         <CustomCard 
            title='Translate Text'
            infoHeader=''
            infoContent={[]}
            isInfoDisabled={true}
            tryIt=''
            isTryItDisabled={true}
            readMore=''
            isReadMoreDisabled={true}
            isVisible={false}
        />
        </Card.Group>
      </div>
    );
};

export default Language;