import React from 'react';
import Title from '../../Title/Title';
import { Button, Card, Container, Divider } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const ImageAnalysis: React.FC = () => {
  return (
    <>
      <Title title='Image Analysis' pervious='/vision' perviousText='Vision' showReadMore={true} readMoreUrl='https://gosia.ai' readMore='Read more on gosia.ai' />
      <Container>
        <h3>v4</h3>
        <Card.Group>
          <Button as={Link} to='/vision/imageAnalysis/v4/readText'>
            <Card>
              <Card.Content header='Read Text' />
            </Card>
          </Button>
          <Button disabled>
            <Card>
              <Card.Content header='Captions' />
            </Card>
          </Button>
          <Button disabled>
            <Card>
              <Card.Content header='Tags' />
            </Card>
          </Button>
          <Button disabled>
            <Card>
              <Card.Content header='Object detection' />
            </Card>
          </Button>
          <Button disabled>
            <Card>
              <Card.Content header='People' />
            </Card>
          </Button>
          <Button disabled>
            <Card>
              <Card.Content header='Smart crop' />
            </Card>
          </Button>
        </Card.Group>
      </Container>
      <Divider clearing />
      <Container>
        <h3>v3.2</h3>
        <Card.Group>
          <Button disabled>
            <Card>
              <Card.Content header='Tags' />
            </Card>
          </Button>
          <Button disabled>
            <Card>
              <Card.Content header='Objects' />
            </Card>
          </Button>
          <Button disabled>
            <Card>
              <Card.Content header='Descriptions' />
            </Card>
          </Button>
          <Button disabled>
            <Card>
              <Card.Content header='Brands' />
            </Card>
          </Button>
          <Button disabled>
            <Card>
              <Card.Content header='Faces' />
            </Card>
          </Button>
          <Button disabled>
            <Card>
              <Card.Content header='Image type' />
            </Card>
          </Button>
          <Button disabled>
            <Card>
              <Card.Content header='Color scheme' />
            </Card>
          </Button>
          <Button disabled>
            <Card>
              <Card.Content header='Landmarks' />
            </Card>
          </Button>
          <Button disabled>
            <Card>
              <Card.Content header='Celebrities' />
            </Card>
          </Button>
          <Button disabled>
            <Card>
              <Card.Content header='Adult content' />
            </Card>
          </Button>
          <Button disabled>
            <Card>
              <Card.Content header='Smart crop' />
            </Card>
          </Button>
        </Card.Group>
      </Container>
      <Divider clearing />
    </>
  );
};

export default ImageAnalysis;
