import React, { useEffect, useState } from 'react';
import { Divider, Form, Grid, Transition } from 'semantic-ui-react';
import styles from './CognitiveSettings.module.scss';

interface Props {
  storageName: string;
}

const CognitiveSettings: React.FC<Props> = (props) => {
  const [cognitiveServicesSettings, setCognitiveServicesSettings] = useState({
    apiKey: '',
    endpoint: '',
  });

  const [visible, setVisible] = useState(false);

  const toggleVisibility = () => {
    setVisible(true);
    setTimeout(() => {
        setVisible(false);
    }, 1000);
  }
  const handleCognitiveServicesSubmit = (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    // Update cognitive services settings in session storage
    const storedSettings = sessionStorage.getItem(props.storageName);
    if (storedSettings) {
      const updatedSettings = {
        ...JSON.parse(storedSettings),
        ...cognitiveServicesSettings,
      };
      sessionStorage.setItem(
        props.storageName,
        JSON.stringify(updatedSettings)
      );
    } else {
      sessionStorage.setItem(
        props.storageName,
        JSON.stringify(cognitiveServicesSettings)
      );
    }
  };

  useEffect(() => {
    const storedSettings = sessionStorage.getItem(props.storageName);
    if (storedSettings) {
      setCognitiveServicesSettings(JSON.parse(storedSettings));
    }
  }, [props.storageName]);

  return (
    <>
      <Form onSubmit={handleCognitiveServicesSubmit}>
        <Form.Group widths='equal'>
          <Form.Field>
            <label>API Key</label>
            <input
              placeholder='API Key'
              value={cognitiveServicesSettings.apiKey}
              onChange={(event) =>
                setCognitiveServicesSettings({
                  ...cognitiveServicesSettings,
                  apiKey: event.target.value,
                })
              }
            />
          </Form.Field>
          <Form.Field>
            <label>Endpoint</label>
            <input
              placeholder='Endpoint'
              value={cognitiveServicesSettings.endpoint}
              onChange={(event) =>
                setCognitiveServicesSettings({
                  ...cognitiveServicesSettings,
                  endpoint: event.target.value,
                })
              }
            />
          </Form.Field>
        </Form.Group>
        <Divider hidden />
        <Grid column={2}>
        <Form.Button onClick={toggleVisibility}>Submit</Form.Button>
        <Transition visible={visible} animation='scale' duration={200}
          divided
          size='small'
          verticalAlign='middle'>
          <div className={styles.saved}>Settings Saved!</div>
        </Transition>
        </Grid>
      </Form>
    </>
  );
};

export default CognitiveSettings;
