import React from 'react';
import {
  Card,
  Divider,
  Icon,
} from 'semantic-ui-react';
import styles from './styles.module.scss';
import CustomCard from '../components/CustomCard/CustomCard';

const Vision: React.FC = () => {
  const imageInfoContent = 
    [
      "It's a service that analyzes images and returns information about them. It can be used to detect objects, faces, and other content within images, and it can also generate a description of an image's content.",
      "To read more, check my blog post, where I explain how/and what I did to create the Image Analysis on this website",
      "You can also try it out by clicking on the 'Try it' button. - You can use your own key/endpoint to check it out or use predefined demo."
    ];
  return (
    <div className={styles.Cognitive}>
      <h1>Vision</h1>
      <Divider clearing />
      <div className={styles.center}>
        <h2>Content here will come soon...</h2>
        <h2>
          <Icon name='star' size='huge' loading color='purple' />
        </h2>
      </div>
      <Card.Group centered>
      <CustomCard 
            title='Optical Character Recognition'
            infoHeader=''
            infoContent={[]}
            isInfoDisabled={true}
            tryIt=''
            isTryItDisabled={true}
            readMore=''
            isReadMoreDisabled={true}
            isVisible={false}
        />
         <CustomCard 
            title='Image Analysis'
            infoHeader='What is Image Analysis?'
            infoContent={imageInfoContent}
            isInfoDisabled={false}
            tryIt="/vision/imageAnalysis"
            isTryItDisabled={false}
            readMore=''
            isReadMoreDisabled={false}
            isVisible={false}
        />
         <CustomCard 
            title='Face'
            infoHeader=''
            infoContent={[]}
            isInfoDisabled={true}
            tryIt=''
            isTryItDisabled={true}
            readMore=''
            isReadMoreDisabled={true}
            isVisible={false}
        />
         <CustomCard 
            title='Spatial Analysis'
            infoHeader=''
            infoContent={[]}
            isInfoDisabled={true}
            tryIt=''
            isTryItDisabled={true}
            readMore=''
            isReadMoreDisabled={true}
            isVisible={false}
        />
      </Card.Group>
    </div>
  );
};

export default Vision;
