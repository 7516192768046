import React, { useEffect, useState } from "react";
import Title from "../../../../Title/Title";
import { ComputerVisionClient } from "@azure/cognitiveservices-computervision";
import { Button } from "semantic-ui-react";
import { ReadOperationResult } from "@azure/cognitiveservices-computervision/esm/models";
const ApiKeyCredentials = require("@azure/ms-rest-js").ApiKeyCredentials;

const ReadText: React.FC = () => {
  const [key, setKey] = useState("");
  const [endpoint, setEndpoint] = useState("");

  useEffect(() => {
    const cognitiveServicesSettings = JSON.parse(
      sessionStorage.getItem("cognitiveServicesSettings") || "{}"
    );
    const visionSettings = JSON.parse(
      sessionStorage.getItem("visionSettings") || "{}"
    );
    setKey(cognitiveServicesSettings.apiKey || visionSettings.apiKey || "");
    setEndpoint(
      cognitiveServicesSettings.endpoint || visionSettings.endpoint || ""
    );
  }, []);

  const analyzeImage = async () => {
    const computerVisionClient = new ComputerVisionClient(
      new ApiKeyCredentials({ inHeader: { "Ocp-Apim-Subscription-Key": key } }),
      endpoint
    );
    const tagsURL =
      "https://i.pinimg.com/originals/99/b3/36/99b33633887aa5bde7fc2765d11f172c.jpg";
    // const tags = (await computerVisionClient.analyzeImage(tagsURL, { visualFeatures: ['Tags'] })).tags;
    // const readText = (await computerVisionClient.read(tagsURL));
    // console.log(readText._response);
    // console.log(tags);
  };

  // Perform read and await the result from URL
  const readTextFromURL = async () => {
      const client = new ComputerVisionClient(
        new ApiKeyCredentials({
          inHeader: { "Ocp-Apim-Subscription-Key": key },
        }),
        endpoint
      );
      const url =
        "https://i.pinimg.com/originals/99/b3/36/99b33633887aa5bde7fc2765d11f172c.jpg";
      let result = (await client.read(url)) as ReadOperationResult;
      let operation = (result as any).operationLocation.split("/").slice(-1)[0];

      await getResults(operation);
  };


  const getResults = async (operationId: string) => {
    const client = new ComputerVisionClient(
        new ApiKeyCredentials({
            inHeader: { "Ocp-Apim-Subscription-Key": key },
        }),
        endpoint
    );
    let result = await client.getReadResult(operationId);
    if (result.status !== "succeeded") {
       await sleep(1000);
         await getResults(operationId);
    }

    if (result.status === "succeeded") {
    console.log(result);
    }
    };

    const sleep = async (ms: number) => {
        return new Promise(resolve => setTimeout(resolve, ms));
      };



  return (
    <>
      <Title
        pervious="/vision/imageAnalysis"
        perviousText="Image Analysis"
        title="Read Text"
        showReadMore={false}
      />
      <Button onClick={readTextFromURL}>Analyze image</Button>
    </>
  );
};

export default ReadText;
