import React from "react";
import { Image, Card, Divider } from "semantic-ui-react";
import cs from '../assets/images/cognitiveservices.png';

const About: React.FC = () => {
    return (
        <>
            <h1>About Cognitive Services Extravaganza!</h1>
            <Divider clearing />
            <Card.Group centered>
                <Card>
                    <Card.Content>
                        <Card.Header>Cognitive Services Extravaganza!</Card.Header>
                        <Card.Description>
                            The idea of this project is to show behaviours of different Cognitive Services in a simple way, on one SPA.
                        </Card.Description>
                    </Card.Content>
                </Card>
            <Card>
                <Image src={cs} wrapped ui={false} />
            </Card>
            <Card>
                <Card.Content>
                    <Card.Header>Check more on my blogpost!</Card.Header>
                    <Card.Description>
                        <h1><a href='https://gosia.ai/2023/04/get-started-with-azure-cognitive-services/'>How to Start with Cognitive Services?</a></h1>
                    </Card.Description>
                </Card.Content>
            </Card>
            </Card.Group>
            <Divider clearing />
        </>
    );
};

export default About;