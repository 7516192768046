import React from "react";
import { Progress, Segment } from "semantic-ui-react";

const NotFound: React.FC = () => {
    return (
        <>
            <h1>Not Found</h1>
            <Segment inverted color='green'>
                <Progress percent={0} inverted progress color='orange'>
                    <p>Not Found page is under construction</p>
                </Progress>
            </Segment>
        </>
    );
};

export default NotFound;