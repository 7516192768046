import React from "react";
import { Card, Divider, Icon } from "semantic-ui-react";
import styles from "./styles.module.scss";
import CustomCard from "../components/CustomCard/CustomCard";

const OpenAI: React.FC = () => {
    return (
        <div className={styles.Cognitive}>
        <h1>Language</h1>
        <Divider clearing />
        <div className={styles.center}>
        <h2>Content here will come soon...</h2>
        <h2>
          <Icon name='star' size='huge' loading color='purple' />
        </h2>
      </div>
        <Card.Group centered>
        <CustomCard 
            title='GPT-3'
            infoHeader=''
            infoContent={[]}
            isInfoDisabled={true}
            tryIt=''
            isTryItDisabled={true}
            readMore=''
            isReadMoreDisabled={true}
            isVisible={false}
        />
        <CustomCard 
            title='GPT-4'
            infoHeader=''
            infoContent={[]}
            isInfoDisabled={true}
            tryIt=''
            isTryItDisabled={true}
            readMore=''
            isReadMoreDisabled={true}
            isVisible={false}
        />
         <CustomCard 
            title='Codex'
            infoHeader=''
            infoContent={[]}
            isInfoDisabled={true}
            tryIt=''
            isTryItDisabled={true}
            readMore=''
            isReadMoreDisabled={true}
            isVisible={false}
        />
         <CustomCard 
            title='Embedded'
            infoHeader=''
            infoContent={[]}
            isInfoDisabled={true}
            tryIt=''
            isTryItDisabled={true}
            readMore=''
            isReadMoreDisabled={true}
            isVisible={false}
        />
         <CustomCard 
            title='DALL-E 2'
            infoHeader=''
            infoContent={[]}
            isInfoDisabled={true}
            tryIt=''
            isTryItDisabled={true}
            readMore=''
            isReadMoreDisabled={true}
            isVisible={false}
        />
        </Card.Group>
      </div>
    );
};

export default OpenAI;