import React from "react";
import { Card, Divider } from "semantic-ui-react";
import styles from "./styles.module.scss";

const Home: React.FC = () => {
  return (
    <>
      <h1>Home</h1>
      <Divider clearing />
      <div className={styles.Cognitive}>
        <div className={styles.center}>
          <h2>Discover the Power of Azure Cognitive Services</h2>
          <h3>Explore our Interactive Demos</h3>
          <Card.Group centered>
            <Card>
              <Card.Content>
                <Card.Header>Speech</Card.Header>
                <Divider />
                <Card.Meta>Conent will come soon</Card.Meta>
                <Card.Description>
                </Card.Description>
              </Card.Content>
            </Card>
            <Card>
              <Card.Content>
                <Card.Header>Language</Card.Header>
                <Divider />
                <Card.Meta>Conent will come soon</Card.Meta>
                <Card.Description>
                </Card.Description>
              </Card.Content>
            </Card>
            <Card>
              <Card.Content>
                <Card.Header>Vision</Card.Header>
                <Divider />
                <Card.Meta>Conent will come soon</Card.Meta>
                <Card.Description>
                </Card.Description>
              </Card.Content>
            </Card>
            <Card>
              <Card.Content>
                <Card.Header>Decision</Card.Header>
                <Divider />
                <Card.Meta>Conent will come soon</Card.Meta>
                <Card.Description>
                </Card.Description>
              </Card.Content>
            </Card>
            <Card>
              <Card.Content>
                <Card.Header>Azure Open AI</Card.Header>
                <Divider />
                <Card.Meta>Conent will come soon</Card.Meta>
                <Card.Description>
                </Card.Description>
              </Card.Content>
            </Card>
            <Card>
              <Card.Content>
                <Card.Header>Azure Cognitive Search</Card.Header>
                <Divider />
                <Card.Meta>Conent will come soon</Card.Meta>
                <Card.Description>
                </Card.Description>
              </Card.Content>
            </Card>
            <Card>
              <Card.Content>
                <Card.Header>Form Recognizer</Card.Header>
                <Divider />
                <Card.Meta>Conent will come soon</Card.Meta>
                <Card.Description>
                </Card.Description>
                </Card.Content>
            </Card>
          </Card.Group>
        </div>
      </div>
    </>
  );
};

export default Home;
