import React from 'react';
import { Button, Card, Divider, Header, Icon, Modal } from 'semantic-ui-react';
import styles from './customCard.module.scss';
import { Link } from 'react-router-dom';

interface Props {
  title: string;
  infoHeader: string;
  infoContent: string[];
  isInfoDisabled: boolean;
  tryIt: any;
  isTryItDisabled: boolean;
  readMore: string;
  isReadMoreDisabled: boolean;
  isVisible: boolean;
}

const CustomCard: React.FC<Props> = (props) => {
  const [open, setOpen] = React.useState(false);

  return (
    <div className={styles.CustomCard}>
      {props.isVisible && (
        <Card color='grey'>
          <Card.Content>
            <Card.Header textAlign='center'>
              <Modal
                basic
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                open={open}
                size='small'
                trigger={
                  <Button
                    circular
                    icon='info'
                    className={styles.icon}
                    color='green'
                    inverted
                    disabled={props.isInfoDisabled}
                  />
                }
              >
                <Header icon>{props.infoHeader}</Header>
                <Modal.Content>
                  {props.infoContent.map((item, index) => {
                    return <p key={index}>{item}</p>;
                  })}
                </Modal.Content>
                <Modal.Actions>
                  <Button color='green' inverted onClick={() => setOpen(false)}>
                    <Icon name='checkmark' /> OK
                  </Button>
                </Modal.Actions>
              </Modal>
              <Divider hidden />
              <div>{props.title}</div>
            </Card.Header>
          </Card.Content>
          <Card.Content extra>
            <div className='ui two buttons'>
              <Button as={Link} to={props.tryIt} basic color='blue' disabled={props.isTryItDisabled}>
                Try it
              </Button>
              <Button basic color='green' disabled={props.isReadMoreDisabled}>
                Read more
              </Button>
            </div>
          </Card.Content>
        </Card>
      )}
    </div>
  );
};

export default CustomCard;
