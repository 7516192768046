import React from "react";
import { Button, Divider, Grid, Icon, Label } from "semantic-ui-react";
import styles from "./Title.module.scss";
import { Link } from "react-router-dom";

interface Props {
  title: string;
  pervious: string;
  perviousText: string;
  showReadMore: boolean;
  readMore?: string;
  readMoreUrl?: string;
}

const Title: React.FC<Props> = (props) => {
  return (
    <>
      <Grid className={styles.Title}>
        <Grid.Column width={4}>
          <Button
            as={Link}
            to={props.pervious}
            labelPosition="right"
            size="big"
          >
            <Button color="blue">
              <Icon
                size="large"
                name="arrow alternate circle left"
                className={styles.icon}
              />
            </Button>
            <Label as="a" basic color="blue" pointing="left">
              Back to {props.perviousText}
            </Label>
          </Button>
        </Grid.Column>
        <Grid.Column width={9}>
          <h1>{props.title}</h1>
        </Grid.Column>
        <Grid.Column width={3}>
          {props.showReadMore && (
            <Button
              as="a"
              href={props.readMoreUrl}
              target="_blank"
              labelPosition="right"
              size="big"
            >
              <Label as="a" basic color="blue" pointing="right">
                {props.readMore}
              </Label>
              <Button color="blue">
                <Icon size="large" name="linkify" className={styles.icon} />
              </Button>
            </Button>
          )}
        </Grid.Column>
      </Grid>
      <Divider clearing />
    </>
  );
};

export default Title;
