import React from 'react';
import styles from './Footer.module.scss'

const Footer: React.FC = () => {
  return (
    <footer className={styles.Footer}>
      <p>© {new Date().getFullYear()} <a href='https://gosia.ai'>Gosia Borzecka</a>. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
