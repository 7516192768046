import React, { useState } from 'react';
import { Accordion, Icon, Divider } from 'semantic-ui-react';

import styles from './styles.module.scss';
import CognitiveSettings from '../components/Settings/CognitiveSettings';

const Settings: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (index: number) => {
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  return (
    <div className={styles.Settings}>
      <h1>Settings</h1>
      <Divider clearing />
      <div className={styles.Accordion}>
        <Accordion styled>
          <Accordion.Title
            active={activeIndex === 0}
            index={0}
            onClick={() => handleClick(0)}
          >
            <h4>
              <Icon name='dropdown' />
              Cognitive Services: All Services
            </h4>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 0}>
            <CognitiveSettings storageName='cognitiveServicesSettings' />
          </Accordion.Content>
          <Accordion.Title
            active={activeIndex === 1}
            index={1}
            onClick={() => handleClick(1)}
          >
            <h4>
              <Icon name='dropdown' />
              Cognitive Services: Vision
            </h4>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 1}>
            <CognitiveSettings storageName='visionSettings' />
          </Accordion.Content>
          <Accordion.Title
            active={activeIndex === 2}
            index={2}
            onClick={() => handleClick(2)}
          >
            <h4>
              <Icon name='dropdown' />
              Cognitive Services: Decision
            </h4>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 2}>
            <CognitiveSettings storageName='decisionSettings' />
          </Accordion.Content>
          <Accordion.Title
            active={activeIndex === 3}
            index={3}
            onClick={() => handleClick(3)}
          >
            <h4>
              <Icon name='dropdown' />
              Cognitive Services: Speech
            </h4>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 3}>
            <CognitiveSettings storageName='speechSettings' />
          </Accordion.Content>
          <Accordion.Title
            active={activeIndex === 4}
            index={4}
            onClick={() => handleClick(4)}
          >
            <h4>
              <Icon name='dropdown' />
              Cognitive Services: Language
            </h4>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 4}>
            <CognitiveSettings storageName='languageSettings' />
          </Accordion.Content>
        </Accordion>
      </div>
    </div>
  );
};

export default Settings;
